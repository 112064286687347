//
//
//
//
//
//
//

export default {
  props: {
    value: Boolean
  }
};
