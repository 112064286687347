//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuCheckBox from 'viu/components/viu-checkbox/viu-checkbox.vue';
import ViuExpandable from 'viu/components/viu-expandable/viu-expandable.vue';
import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import stepMixin from '../lib/step-mixin.js';
import Radio from '../components/radio.vue';
import Button from '../components/button.vue';
import StepLayout from './step-layout.vue';
import SliderComponente from './slider-componente.vue';
import { createRate } from './Rate';

export default {
  components: {
    StepLayout,
    Button,
    Radio,
    ViuCheckBox,
    ViuExpandable,
    ViuBox,
    ViuHeader,
    ViuLabel,
    ViuInput,
    ViuSwitch,
    SliderComponente
  },
  mixins: [stepMixin],
  props: {
    rates: {
      type: Array,
      required: true
    },
    selectedRates: {
      type: Array,
      required: false,
      default: () => []
    },
    hiredPower: {
      type: Array,
      required: true
    },
    tarifaAtr: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    myRates: [],
    powersStates: [
      { value: 'grouped', text: 'Agrupados' },
      { value: 'individual', text: 'Individuales' }
    ]
  }),
  computed: {
    allRatesSelected() {
      return this.myRates.every(rate => rate.selected);
    },
    ratesSelectedCount() {
      return this.myRates.filter(rate => rate.selected).length;
    }
  },
  watch: {
    hiredPower(array) {
      this.myRates.forEach(myRate => {
        myRate.hiredPower = array;
      });
    }
  },
  async created() {
    if (this.selectedRates.length === 0) {
      this.myRates = this.rates.map(rate => {
        return createRate(rate, this.hiredPower);
      });

      return;
    }
    this.hiredPower = this.selectedRates[0].hiredPower;
    this.myRates = this.selectedRates;
  },
  methods: {
    onContinue() {
      this.validateHiredPower();

      this.loading = true;
      this.$emit('continue', {
        selectedRates: this.myRates
      });
    },
    validateHiredPower() {
      const filteredHiredPower = this.myRates[0].hiredPower.filter(power => power !== '');

      if (filteredHiredPower.length <= 0) {
        this.$notify.error('Debe informar las potencias contratadas');
        return;
      }

      if (this.tarifaAtr === '018' && filteredHiredPower.length < 2) {
        this.$notify.error('Debe informar la potencia contratada para todos los periodos.');
        return;
      }

      if (filteredHiredPower.length < 6) {
        this.$notify.error('Debe informar la potencia contratada para todos los periodos.');
      }
    },
    expandRate(rate) {
      rate.expandible = !rate.expandible;
    },
    selectRate(rate) {
      rate.selected = !rate.selected;
    },
    selectAllRates() {
      return this.allRatesSelected
        ? this.changeAllRatesState(false)
        : this.changeAllRatesState(true);
    },
    changeAllRatesState(state) {
      this.myRates.forEach(rate => {
        rate.selected = state;
      });
    }
  }
};
