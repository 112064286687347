//
//
//
//
//
//
//

export default {
  props: {
    disabled: Boolean,
    loading: Boolean,
    buttonStyle: {
      type: String,
      default: 'heavy',
      validator: v => ['heavy', 'outlined'].includes(v)
    },
    color: {
      type: String,
      default: 'blue',
      validator: v => ['blue', 'black', 'success'].includes(v)
    },
    size: {
      type: String,
      default: 'medium',
      validator: v => ['medium', 'large'].includes(v)
    }
  }
};
